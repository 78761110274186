<template>
    <v-dialog
        v-if="isVisible"
        v-model="isVisible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="isPending" >

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{$t("Загрузка...")}}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                <v-card flat>
                    <v-card-text>
                        
                        <v-form ref="form" lazy-validation>

                            <v-row no-gutters>

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("Энергоснабжающая_организация")}}                                        
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-select
                                        return-object
                                        :items="GetIQala0103X017EnergySupplyOrgsList"
                                        v-model="energySupplyOrgId"
                                        :item-text="item => $refLocale(item, $i18n.locale)"
                                        item-value="id"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        clearable
                                        @click:clear="energySupplyOrgId = null"
                                        append-icon="fas fa-chevron-down"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        :placeholder="energySupplyOrgPlaceholder"
                                    >
                                        <template v-slot:selection="data">
                                            {{ $t(data.item.Value) }}
                                        </template>

                                        <template v-slot:item="data">
                                            {{ $t(data.item.Value) }}
                                        </template>
                                    </v-select>
                                </v-col>

                            </v-row>
                        </v-form>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="isValid"
                >
                    {{ $t("Отправить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "CSCFormAnAnswer0103X017Dlg",
   
    data () {
        return {
            title: "Сформировать_ответ",
        }
    },
    computed: {
        ...mapGetters('dialogs/cscFormAnAnswer0103X017', ['isVisible', 'isValid', 'isPending']),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',            
        }),
        ...mapGetters('references', ['GetIQala0103X017EnergySupplyOrgsList']),

        energySupplyOrgId: {
            get: function() {
                return this.$store.getters['dialogs/cscFormAnAnswer0103X017/energySupplyOrgId'];
            },
            set: function(v) {
                this.$store.commit('dialogs/cscFormAnAnswer0103X017/SET_ENERGY_SAVING_ORG_ID', v); 
                this.validateFormMethod();
            }
        },

        energySupplyOrgPlaceholder(){
            return this.$t("Выберите_значение___");
        }

    },
    methods: {
        ...mapActions('dialogs/cscFormAnAnswer0103X017', ['ok', 'cancel']),
        
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    }
}
</script>